import { registerApplication, start } from 'single-spa';

registerApplication(
    'root',
    () => import('./App'),
    location => location.hash.includes('/home')
)

//Carrega com delay para dar tempo da div ser carregada
setTimeout(() => {
    registerApplication(
        'counseling',
        () => import('../containers/counseling'),
        location => document.querySelector('#counseling')
    )
}, 200);

registerApplication(
    'login',
    () => import('../containers/login'),
    location => location.hash.includes('/login') // || localStorage.getItem('@token_key') === null
)

registerApplication(
    'isntAuth',
    () => import('../containers/redirects/isntAuth'),
    location => (location.hash === '' || location.hash === '#/') || (location.hash.includes('/home') && localStorage.getItem('@token_key') === null)
)


start();